@import "https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap";
html, body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  overflow-x: hidden;
}

.button-vacio {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

div#main {
  flex-direction: column;
  width: 100vw;
  display: flex;
}

section {
  width: 100vw;
  position: relative;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  width: 100vmax;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}

h1 {
  margin: 0;
  font-family: Cormorant Garamond, serif;
  font-size: 7rem;
  font-weight: 400;
  line-height: .8;
}

h2 {
  margin: 0;
  padding-top: 1rem;
  font-size: 2rem;
  font-weight: 400;
}

h3 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
}

p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
}

p.large {
  padding: 15px;
  font-size: 24px;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.containerr {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: flex;
}

.containerr .box {
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 24px 16px;
  display: flex;
}

@media only screen and (width <= 690px) {
  .containerr .box {
    flex-direction: column;
    padding: 2rem;
  }

  .containerr .Informacion .small {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .containerr img {
    justify-content: center;
    place-items: center;
    width: 70%;
    margin-left: 15%;
  }

  .container-contact {
    flex-direction: column;
    display: flex;
  }

  .container-contact-img {
    align-items: start;
    margin-left: 0;
  }

  .form-container {
    flex: auto;
    justify-content: center;
    margin-left: 0;
    display: flex;
  }
}

@media only screen and (width <= 600px) {
  .container-contact-img {
    display: none;
  }

  .form-container {
    width: 100%;
  }
}

@media screen {
  .containerr .box {
    animation: 1s slideInLeft;
  }
}

.containerr .Informacion {
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;
  display: flex;
}

.containerr .redes {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.containerr .redes .btn {
  padding: 0;
}

.containerr .tecnologias {
  grid-gap: .5rem;
  grid-template-columns: repeat(auto-fit, minmax(min(8.2rem, 100%), 1fr));
  display: grid;
}

img.socialIcon {
  width: 30px;
  height: 30px;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: 2s infinite bounce;
}

.btn {
  background: #e3edf7;
  border: 1px solid #0000;
  border-radius: 10px;
  width: 3rem;
  height: 3rem;
  transition: transform .5s;
  box-shadow: 6px 6px 10px -1px #00000026, -6px -6px 10px -1px #ffffffb3;
}

.btn:hover {
  border: 1px solid #0000001a;
  transform: translateY(.5em);
  box-shadow: inset 4px 4px 6px -1px #0003, inset -4px -4px 6px -1px #ffffffb3, -.5px -.5px #fff, .5px .5px #00000026, 0 12px 10px -10px #0000000d;
}

.btn svg {
  transition: transform .5s;
}

.btn img {
  width: 2rem;
  height: 3rem;
}

.btn:hover svg, .btn img:hover {
  fill: #333;
  transform: scale(.9);
}

.margen1rem {
  margin-left: 1rem;
  margin-right: 1rem;
}

@media screen and (width <= 460px) {
  .letrasChicasMedia {
    font-size: 1rem;
  }

  .letrasChicasHeader {
    font-size: .8rem;
  }

  #sobremi {
    align-items: left;
    font-size: 1rem;
  }
}

.container__DescargarCV {
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  display: flex;
}

.container__DescargarCV a {
  text-decoration: none;
}

.buttonCV {
  cursor: pointer;
  background-color: #209978;
  border: 1px solid #17795e;
  align-items: center;
  width: 150px;
  height: 40px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.buttonCV, .button__icon, .button__text {
  transition: all .3s;
}

.buttonCV .button__text {
  color: #fff;
  font-weight: 600;
  transform: translateX(22px);
}

.buttonCV .button__icon {
  background-color: #17795e;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 100%;
  display: flex;
  position: absolute;
  transform: translateX(109px);
}

.buttonCV .svg {
  fill: #fff;
  width: 20px;
}

.buttonCV:hover {
  background: #17795e;
}

.buttonCV:hover .button__text {
  color: #0000;
}

.buttonCV:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.buttonCV:active .button__icon {
  background-color: #146c54;
}

.buttonCV:active {
  border: 1px solid #146c54;
}
/*# sourceMappingURL=index.cdae1dc3.css.map */
