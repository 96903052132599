/**
* CSS file to hold styles that apply across the application
*/

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&family=Montserrat:wght@200;400;600;800&display=swap");

html,
body {
  font-family: "Montserrat", sans-serif;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
  width: 100%;
  scroll-behavior: smooth;
}


.button-vacio{
  background-color: transparent;
  border: none;
  cursor: pointer;

}

div#main {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

section {
  position: relative;
  width: 100vw;
}

section.min-height {
  min-height: 100vh;
}

section.padding {
  padding: 5rem 0;
}

section img.background {
  height: 100%;
  left: 50%;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100vmax;
  z-index: -1;
}

h1 {
  font-family: "Cormorant Garamond", serif;
  font-size: 7rem;
  font-weight: 400;
  line-height: 0.8;
  margin: 0;
}

h2 {
  font-size: 2rem;
  font-weight: 400;
  margin: 0;
  padding-top: 1rem;
}

h3 {
  font-weight: 400;
  font-size: 1.25rem;
  margin: 0;
}

p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

p.large {
  font-size: 24px;
  padding: 15px;
}

p.small {
  font-size: 15px;
}

hr {
  margin: 2.5rem;
}

li {
  line-height: 1.7;
}

li::marker {
  color: #4e567e;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #4e567e;
}

.containerr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

.containerr .box {
  margin-bottom: 2rem;
  border-radius: 10px;
  border: 1px solid rgb(204, 204, 204);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 24px 16px;
  text-align: center;
  box-sizing: border-box;
}

@media only screen and (max-width: 690px) {
  .containerr .box {
    flex-direction: column;
    padding: 2rem;
  }
  .containerr .Informacion .small{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .containerr img{
    width: 70%;
    align-items: center;
    justify-content: center;
    place-items: center;
    margin-left: 15%;
  }

  /*aca va a estar lo de contact*/
  .container-contact{
    display: flex;
    flex-direction: column;

  }
  .container-contact-img{
    align-items: start;
    margin-left: 0;
  }
  .form-container{
    display: flex;
    flex: auto;
    justify-content: center;
    margin-left: 0;
  }
}
@media only screen and (max-width: 600px){
  .container-contact-img {
    display: none; /* Esto ocultará la imagen */
  }
  .form-container {
    width: 100%; /* Esto hará que el formulario ocupe todo el ancho disponible */
  }

}
@media screen {
  .containerr .box {
    animation: slideInLeft 1s;
  }
}

.containerr .Informacion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1rem;

}

.containerr .redes {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.containerr .redes .btn{
  padding: 0rem;
}



.containerr .tecnologias {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(8.2rem,100%), 1fr));
  grid-gap:0.5rem;

}


img.socialIcon {
  height: 30px;
  width: 30px;
}


/* add a slide In Left animation */
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}



/*Use Copilot to help you animate the scroll down arrow in your Home component to bounce up and down on your page.

Hint: In your styles.css file, use comments to start to tell Copilot what you want to do. See what is suggests, adjust your prompts, and see how it guides you to get your arrow to bounce.*/

/* Add a bounce animation */
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}


.btn {

  height: 3rem;
  width: 3rem;
  background: #e3edf7;
  border-radius: 10px;
  box-shadow: 6px 6px 10px -1px rgba(0,0,0,0.15),
	      -6px -6px 10px -1px rgba(255,255,255,0.7);
  border: 1px solid rgba(0,0,0,0);
  transition: transform 0.5s;
}

.btn:hover {
  box-shadow: inset 4px 4px 6px -1px rgba(0,0,0,0.2),
	      inset -4px -4px 6px -1px rgba(255,255,255,0.7),
	      -0.5px -0.5px 0px rgba(255,255,255,1),
	      0.5px 0.5px 0px rgba(0,0,0,0.15),
	      0px 12px 10px -10px rgba(0,0,0,0.05);
  border: 1px solid rgba(0,0,0,0.1);
  transform: translateY(0.5em);
}

.btn svg {
  transition: transform 0.5s;
}
.btn img{
  height: 3rem;
  width: 2rem;
}

.btn:hover svg {
  transform: scale(0.9);
  fill: #333333;
}

.btn img:hover {
  transform: scale(0.9);
  fill: #333333;
}

.margen1rem{
  margin-left: 1rem;
  margin-right: 1rem;
}


@media screen and (max-width: 460px) {
  .letrasChicasMedia {
    font-size: 1rem;
  }
  .letrasChicasHeader {
    font-size: 0.8rem;
  }

  #sobremi{
    font-size: 1rem;
    align-items: left;
  }
}






.container__DescargarCV{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 1rem;
  
}

.container__DescargarCV a {
  text-decoration: none;

}
.buttonCV {
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #17795E;
  background-color: #209978;
  overflow: hidden;

}

.buttonCV, .button__icon, .button__text {
  transition: all 0.3s;
}

.buttonCV .button__text {
  transform: translateX(22px);
  color: #fff;
  font-weight: 600;
}

.buttonCV .button__icon {
  position: absolute;
  transform: translateX(109px);
  height: 100%;
  width: 39px;
  background-color: #17795E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonCV .svg {
  width: 20px;
  fill: #fff;
}

.buttonCV:hover {
  background: #17795E;
}

.buttonCV:hover .button__text {
  color: transparent;
}

.buttonCV:hover .button__icon {
  width: 148px;
  transform: translateX(0);
}

.buttonCV:active .button__icon {
  background-color: #146c54;
}

.buttonCV:active {
  border: 1px solid #146c54;
}